import ImageSm from "../../components/ImageSm";
import { Link } from "gatsby";
import React from "react";

const NotFoundPage = () => {
  return (
    <div className="container py-5 mb-lg-3">
      <div className="row justify-content-center pt-lg-4 text-center">
        <div className="col-lg-5 col-md-7 col-sm-9">
          <ImageSm
            className="d-block mx-auto mb-5"
            filename="404.png"
            width="340"
            alt="404 Error"
          />
          <h1 className="h3">404 error</h1>
          <h3 className="h5 font-weight-normal mb-4">
            We can't seem to find the page you are looking for.
          </h3>
          <p className="font-size-md mb-4">
            <Link to="/" alt="home">
              It should be there
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
export default NotFoundPage;
